import { useEffect, useState, useRef } from 'react'

interface UseVisibilityObserverOptions {
  root?: Element | null
  rootMargin?: string
  threshold?: number | number[]
}

export const useVisibilityObserver = <T extends HTMLElement>(
  options: UseVisibilityObserverOptions = {}
): [number, React.RefObject<T>] => {
  const [visibilityPercentage, setVisibilityPercentage] = useState(0)
  const targetRef = useRef<T>(null) // Explicitly type as HTMLDivElement

  useEffect(() => {
    const target = targetRef.current

    if (!target) return

    const observerCallback: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        const intersectionRatio = entry.intersectionRatio
        const visibility = Math.round(intersectionRatio * 100)
        setVisibilityPercentage(visibility)
      })
    }

    const observer = new IntersectionObserver(observerCallback, options)
    observer.observe(target)

    return () => {
      observer.disconnect()
    }
  }, [options])

  return [visibilityPercentage, targetRef]
}
