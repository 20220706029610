import type React from 'react'

import type { ElementVisibilityProps } from './types'

type Props = ElementVisibilityProps & {
  Scale: React.ReactElement
}

const ElementVisibilityContainer = ({ height, width, Scale }: Props) => {
  return (
    <div
      style={{
        position: 'relative',
        height: typeof height === 'number' ? `${height}px` : height,
        width: typeof width === 'number' ? `${width}px` : width
      }}>
      {Scale}
    </div>
  )
}

export default ElementVisibilityContainer
