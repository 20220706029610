import ElementVisibility from '../../components/ElementVisibility'

import { useVisibilityObserver } from '../../hooks/useVisibilityObserver'

import './index.scss'

const AdcmVisibilityTracker = () => {
  const [visibilityPercentage, targetRef] = useVisibilityObserver<HTMLAnchorElement>({
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No margin around the root
    threshold: Array.from({ length: 101 }, (_, i) => i / 100) // Track every 1% increment
  })

  return (
    <div className="adcm-visibility">
      <h2 className="adcm-visibility__title">Тестирование Adcm.ElementVisibility</h2>

      <div className="adcm-visibility__filler"></div>

      <div className="adcm-visibility_sticky">
        <p>Visibility: {visibilityPercentage}%</p>
      </div>
      <a
        ref={targetRef}
        id="element_visibility"
        href="https://dmg.digitaltarget.ru/1/7681/i/i?sid=hj88d9AS9nadan"
        className="adcm-visibility__banner">
        <ElementVisibility height="70vh" width="70vw" borderOnly={true} hideHorizontal={true} hideVertical={false} />
      </a>
    </div>
  )
}

export default AdcmVisibilityTracker
