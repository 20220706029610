import { Routes, Route } from 'react-router-dom'

// import Home from './views/Home'
// import Other from './views/Other'
// import Main from './views/Main'
// import B1T1 from './views/B1/T1'
// import B1T2 from './views/B1/T2'
// import B1T3 from './views/B1/T3'
// import B2T1 from './views/B2/T1'
// import B2T2 from './views/B2/T2'
// import B2T3 from './views/B2/T3'
import AdcmVisibilityTracker from './views/AdcmVisibilityTracker'

import './App.css'

function App() {
  return (
    <div className="App">
      {/* <h1 className="App__title">Тестирование Adcm на React</h1> */}
      <div className="App__content">
        <Routes>
          {/* <Route path="/" element={<Home />} />
          <Route path="main" element={<Main />} />
          <Route path="other" element={<Other />} />
          <Route path="b1/t1" element={<B1T1 />} />
          <Route path="b1/t2" element={<B1T2 />} />
          <Route path="b1/t3" element={<B1T3 />} />
          <Route path="b2/t1" element={<B2T1 />} />
          <Route path="b2/t2" element={<B2T2 />} />
          <Route path="b2/t3" element={<B2T3 />} /> */}
          <Route path="/" element={<AdcmVisibilityTracker />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
