import type { ScaleProps } from './types'

const Scale = ({ hideVertical, hideHorizontal, borderOnly }: ScaleProps) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'grid',
        gridTemplateRows: 'repeat(10, 1fr)',
        gridTemplateColumns: 'repeat(10, 1fr)',
        pointerEvents: 'none',
        border: '1px solid rgba(0, 0, 0, 0.5)'
      }}>
      {Array.from({ length: 10 }).map((_, rowIndex) =>
        Array.from({ length: 10 }).map((_, colIndex) => {
          const isBorderCell = rowIndex === 0 || rowIndex === 9 || colIndex === 0 || colIndex === 9

          const isHorizontalPercentage = !hideHorizontal && (!borderOnly || isBorderCell)

          const isVerticalPercentage = !hideVertical && (!borderOnly || isBorderCell)

          // Content should only show the appropriate percentage
          let content = ''
          if (isHorizontalPercentage && !isVerticalPercentage) {
            if (rowIndex === 9) {
              content = `${(colIndex + 1) * 10}%` // Horizontal scale
            }
          } else if (isVerticalPercentage && !isHorizontalPercentage) {
            if (colIndex === 9) {
              content = `${(rowIndex + 1) * 10}%` // Vertical scale
            }
          } else if (isHorizontalPercentage && isVerticalPercentage) {
            if (borderOnly) {
              if (colIndex === 9 || rowIndex === 9) {
                content = `${(rowIndex + 1) * 10}% / ${(colIndex + 1) * 10}%` // Both scales
              }
            } else {
              content = `${(rowIndex + 1) * 10}% / ${(colIndex + 1) * 10}%` // Both scales
            }
          }

          return (
            <div
              key={`${rowIndex}-${colIndex}`}
              style={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                fontSize: '0.8em',
                color: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                visibility: content ? 'visible' : 'hidden' // Hide if no content
              }}>
              {content}
            </div>
          )
        })
      )}
    </div>
  )
}

export default Scale
