import ElementVisibilityContainer from './Container'
import Scale from './Scale'

import type { ElementVisibilityProps } from './types'

const ElementVisibility = ({ height, width, hideVertical, hideHorizontal, borderOnly }: ElementVisibilityProps) => {
  return (
    <ElementVisibilityContainer
      height={height}
      width={width}
      Scale={<Scale hideVertical={hideVertical} hideHorizontal={hideHorizontal} borderOnly={borderOnly} />}
    />
  )
}

export default ElementVisibility
